import Alpine from "alpinejs";
window.Alpine = Alpine;

// Axios
import axios from "axios";
window.axios = axios;

const _token = document.querySelector("meta[name=csrf-token]");
if (_token && _token.content) {
    window.axios.defaults.headers.common["X-CSRF-Token"] = _token.content;
    window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
} else {
    console.error("No CSRF token found.");
}

window.axios.interceptors.response.use(
    (response) => {
        return response;
    },

    (error) => {
        if (error.response && 419 === error.response.status) {
            window.location.reload();
        }

        return Promise.reject(error);
    }
);

// Boostrap
import { Modal } from "bootstrap";
window.bsModal = Modal;

// JQuery
import $ from "jquery";
window.$ = window.jQuery = $;

// SmoothScroll
import SmoothScroll from "smooth-scroll";
window.SmoothScroll = SmoothScroll;

// Toastr
import toastr from "toastr";
window.toastr = toastr;

import * as echarts from "echarts";
window.echarts = echarts;

import "tinymce/tinymce";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/content/default/content.min.css";
import "tinymce/skins/content/default/content.css";
import "tinymce/icons/default/icons";
import "tinymce/themes/silver/theme";
import "tinymce/models/dom/model";
window.tinymce = tinymce;

import "text-spinners/spinners.css";
