export async function onExportCardBtnClick(path) {
    try {
        const getUsers = await axios.get(path);

        const csvFile = new Blob([await getUsers.data], {
            type: "application/pdf",
        });
        const csvFileUrl = URL.createObjectURL(csvFile);
        location.href = csvFileUrl;
    } catch (error) {
        toastr.error(error.response.data.message);
    }
}

export async function approveTransferRequest(transferRequestId, target) {
    target.setAttribute("disabled", "disabled");
    try {
        await axios.put(`/transfer-requests/${transferRequestId}`);
        window.utils.showToastAndReload("Richiesta di trasferimento approvata");
    } catch (e) {
        window.toastr.error("Errore durante l'approvazione della richiesta");
    }
}

export async function rejectTransferRequest(transferRequestId) {
    try {
        await axios.delete(`/transfer-requests/${transferRequestId}`);
        window.utils.showToastAndReload("Richiesta di trasferimento rifiutata");
    } catch (e) {
        window.toastr.error("Errore durante il rifiuto della richiesta");
    }
}
