export async function officeIndex() {
    const filtersNav = document.getElementById("table_filters_nav");
    const buttons = filtersNav?.querySelectorAll("button");

    const officesTable = document.getElementById("offices_table");
    if (!officesTable) {
        console.warn("Offices table not found.");
        return;
    }

    for (let button of buttons) {
        button.addEventListener("click", () => {
            const datatable = Alpine.store(officesTable.id);
            if (!datatable) {
                console.warn("Datatable not found");
                return;
            }

            const filterType = button.getAttribute("filter");
            datatable.resetFilters();

            const filter = { by: "", value: "", countFiltered: false };
            switch (filterType) {
                case "national":
                    filter.by = "officeType.name";
                    filter.value = ["nazionale"];
                    break;
                case "regional":
                    filter.by = "officeType.name";
                    filter.value = ["regionale"];
                    break;
                case "provincial":
                    filter.by = "officeType.name";
                    filter.value = ["provinciale"];
                    break;
            }

            const jurisdictionFilterString = document.getElementById(
                "jurisdiction_filter"
            )?.value;

            let filters = [];
            try {
                filters = JSON.parse(jurisdictionFilterString) ?? [];
            } catch (e) {
                console.warn("Jurisdiction string is not a valid JSON");
            }

            if (filter.by && filter.value) {
                filters = filters.concat(filter);
            }

            datatable.setFilters(filters);
            datatable.redraw();

            if (!button.classList.contains("active")) {
                const activeBtn =
                    filtersNav.getElementsByClassName("active")?.[0];

                if (activeBtn) {
                    activeBtn.classList.remove("active");
                }

                button.classList.add("active");
            }
        });
    }
}
