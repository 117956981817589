export async function overview(
    notificationsCount,
    messageCount,
    showNotifications
) {
    // Get added this week
    const count = await window.statistics.addedThisWeek(false);
    const addedThisWeekCounter = document.getElementById("this_week_counter");
    addedThisWeekCounter.innerText = count;

    // Get offices
    const getOfficesUrl = window.utils.createApiUrl(
        "/api/offices",
        0,
        ["id"],
        [],
        []
    );

    const getOfficesResponse = await window.axios.get(getOfficesUrl);
    const offices = await getOfficesResponse.data.data;
    const officesCounter = document.getElementById("offices_counter");
    officesCounter.innerText = offices.length;

    // Get disabled users
    const disabledCount = await window.statistics.disabledLastThirtyDays(false);

    const disabledCounter = document.getElementById(
        "disabled_last_thirty_days"
    );
    disabledCounter.innerText = disabledCount;

    // Init charts
    window.statistics.userOverview("chart_1", false, null, null);
    window.statistics.memberCgsRatio("chart_2", false, null, null, null, false);
    window.statistics.countByMembershipTypes("chart_3", false);

    if (showNotifications) {
        let text = "";
        if (notificationsCount > 0) {
            text += notificationsCount + " nuove notifiche. <br>";
        }

        if (messageCount > 0) {
            text += messageCount + " nuovi messaggi. <br>";
        }

        window.toastr.info(text, "Notifiche ultime 24 ore", {
            iconClass: "notifications-toast",
        });
    }
}
