const stats = {
    TRENDS: "trends",
    RATIOS: "ratios",
    INSURANCE: "insurance",
    MEMBER_CGS_RATIO: "member_cgs_ratio",
    ENABLED_DISABLED_RATIO: "enabled_disabled_ratio",
    PAST_UNIONS_RATIO: "past_unions_ratio",
    COMPANY_TYPE_RATIO: "company_type_ratio",
};

export function onFilterSelected(filterSelectId) {
    const $filterSelect = $(`#${filterSelectId}`);
    $filterSelect.on("select2:select", async () => {
        const option = $filterSelect.select2("data");
        window.Alpine.store("filter", option[0].slug);
    });

    $filterSelect.on("select2:clear", async () => {
        window.Alpine.store("filter", "");
    });
}

export function onStatsSelected() {
    // Show/hide fields based on selected stats
    const $statsSelect = $("#stats");
    $statsSelect.on("select2:select", async () => {
        const option = $statsSelect.select2("data");
        switch (option[0].slug) {
            case stats.TRENDS:
                window.Alpine.store("isFilterRequired", 1);
                window.Alpine.store("canFilterByCompany", 1);
                window.Alpine.store("isStartRequired", 1);
                window.Alpine.store("isEndRequired", 1);
                window.Alpine.store("isGranularityRequired", 1);
                window.Alpine.store("isTotalSumRequired", 1);
                break;
            case stats.RATIOS:
                window.Alpine.store("isFilterRequired", 0);
                window.Alpine.store("isStartRequired", 0);
                window.Alpine.store("isEndRequired", 1);
                window.Alpine.store("isGranularityRequired", 0);
                window.Alpine.store("isTotalSumRequired", 0);
                break;
            case stats.INSURANCE:
                window.Alpine.store("isFilterRequired", 1);
                window.Alpine.store("canFilterByCompany", 1);
                window.Alpine.store("isStartRequired", 0);
                window.Alpine.store("isEndRequired", 1);
                window.Alpine.store("isGranularityRequired", 0);
                window.Alpine.store("isTotalSumRequired", 0);
                break;
            case stats.MEMBER_CGS_RATIO:
                window.Alpine.store("isFilterRequired", 1);
                window.Alpine.store("canFilterByCompany", 1);
                window.Alpine.store("isStartRequired", 0);
                window.Alpine.store("isEndRequired", 1);
                window.Alpine.store("isGranularityRequired", 0);
                window.Alpine.store("isTotalSumRequired", 0);
                break;
            case stats.ENABLED_DISABLED_RATIO:
                window.Alpine.store("isFilterRequired", 1);
                window.Alpine.store("canFilterByCompany", 1);
                window.Alpine.store("isStartRequired", 0);
                window.Alpine.store("isEndRequired", 1);
                window.Alpine.store("isGranularityRequired", 0);
                window.Alpine.store("isTotalSumRequired", 0);
                break;
            case stats.PAST_UNIONS_RATIO:
                window.Alpine.store("isFilterRequired", 1);
                window.Alpine.store("canFilterByCompany", 1);
                window.Alpine.store("isStartRequired", 0);
                window.Alpine.store("isEndRequired", 1);
                window.Alpine.store("isGranularityRequired", 0);
                window.Alpine.store("isTotalSumRequired", 0);
                break;
            case stats.COMPANY_TYPE_RATIO:
                window.Alpine.store("isFilterRequired", 1);
                window.Alpine.store("canFilterByCompany", 0);
                window.Alpine.store("isStartRequired", 0);
                window.Alpine.store("isEndRequired", 1);
                window.Alpine.store("isGranularityRequired", 0);
                window.Alpine.store("isTotalSumRequired", 0);
                break;
        }

        const filterSelects = document.querySelectorAll(
            "select[name='filter']"
        );
        for (const select of filterSelects) {
            const $select = $(select);
            $select.val(null).trigger("change");
            window.Alpine.store("filter", "");
        }
    });

    $statsSelect.on("select2:clear", async () => {
        window.Alpine.store("isFilterRequired", 0);
        window.Alpine.store("isStartRequired", 0);
        window.Alpine.store("isEndRequired", 0);
        window.Alpine.store("isGranularityRequired", 0);
        window.Alpine.store("isTotalSumRequired", 0);
        window.Alpine.store("filter", "");
    });
}

export async function submitChartOptions(chartContainerId = "chart_container") {
    const {
        statsData,
        filterData,
        filterValueData,
        start,
        end,
        granularityData,
        validationFailed,
        totalSum,
    } = validateChartOptions();

    if (validationFailed) {
        console.warn("Validation failed");
        return;
    }

    let chart = window.statistics.index.charts[chartContainerId];
    chart?.dispose();

    // Show loader toast
    window.toastr.info("Attendere il caricamento del grafico", "Attendere...", {
        timeOut: 0,
        extendedTimeOut: 0,
    });

    // Load chart based on selected stats
    switch (statsData[0].slug) {
        case stats.TRENDS: {
            const queryParams = { sum: totalSum };
            if (filterData && filterData.length > 0) {
                queryParams.by = filterData[0].slug;
            }

            if (filterValueData && filterValueData.length > 0) {
                queryParams.value = filterValueData[0].id;
            }

            if (start && start.length > 0) {
                queryParams.start = start;
            }

            if (end && end.length > 0) {
                queryParams.end = end;
            }

            if (granularityData && granularityData.length > 0) {
                queryParams.granularity = granularityData[0].slug;
            }

            chart = await window.statistics.registeredDisabledTrend(
                chartContainerId,
                statsData[0].url,
                queryParams
            );
            break;
        }
        case stats.RATIOS: {
            const untilDate = end && end.length > 0 ? end : null;
            chart = await window.statistics.usersRatios(
                chartContainerId,
                statsData[0].url,
                untilDate
            );
            break;
        }
        case stats.INSURANCE: {
            const filterBy =
                filterData && filterData.length > 0 ? filterData[0].slug : null;
            const filterValue =
                filterValueData && filterValueData.length > 0
                    ? filterValueData[0].id
                    : null;
            const untilDate = end && end.length > 0 ? end : null;

            chart = await window.statistics.insuranceOverview(
                chartContainerId,
                false,
                filterBy,
                filterValue,
                untilDate,
                true
            );
            break;
        }
        case stats.ENABLED_DISABLED_RATIO: {
            const filterBy =
                filterData && filterData.length > 0 ? filterData[0].slug : null;
            const filterValue =
                filterValueData && filterValueData.length > 0
                    ? filterValueData[0].id
                    : null;
            const untilDate = end && end.length > 0 ? end : null;

            chart = await window.statistics.userOverview(
                chartContainerId,
                false,
                filterBy,
                filterValue,
                untilDate,
                true
            );
            break;
        }
        case stats.MEMBER_CGS_RATIO: {
            const filterBy =
                filterData && filterData.length > 0 ? filterData[0].slug : null;
            const filterValue =
                filterValueData && filterValueData.length > 0
                    ? filterValueData[0].id
                    : null;
            const untilDate = end && end.length > 0 ? end : null;

            chart = await window.statistics.memberCgsRatio(
                chartContainerId,
                false,
                filterBy,
                filterValue,
                untilDate
            );
            break;
        }
        case stats.PAST_UNIONS_RATIO: {
            const filterBy =
                filterData && filterData.length > 0 ? filterData[0].slug : null;
            const filterValue =
                filterValueData && filterValueData.length > 0
                    ? filterValueData[0].id
                    : null;
            const untilDate = end && end.length > 0 ? end : null;

            chart = await window.statistics.fromPastUnionsRatio(
                chartContainerId,
                filterBy,
                filterValue,
                untilDate
            );
            break;
        }

        case stats.COMPANY_TYPE_RATIO: {
            const filterBy =
                filterData && filterData.length > 0 ? filterData[0].slug : null;
            const filterValue =
                filterValueData && filterValueData.length > 0
                    ? filterValueData[0].id
                    : null;
            const untilDate = end && end.length > 0 ? end : null;

            chart = await window.statistics.usersByCompanyType(
                chartContainerId,
                filterBy,
                filterValue,
                untilDate,
                statsData[0].cgs
            );
            break;
        }
    }

    window.toastr.clear();

    window.statistics.index.charts[chartContainerId] = chart;
}

function validateChartOptions() {
    let validationFailed = false;

    const $statsSelect = $("#stats");
    const statsData = $statsSelect.select2("data");
    if (statsData && statsData.length > 0) {
        window.Alpine.store("statsRequired", false);
    } else {
        window.Alpine.store("statsRequired", true);
        validationFailed = true;
    }

    let filterData,
        filterValueData,
        start,
        end,
        granularityData,
        totalSum = null;

    // Validate filter and filter value
    if (statsData && statsData.length > 0) {
        const isFilterRequired = window.Alpine.store("isFilterRequired");
        if (isFilterRequired) {
            const $filterSelect = $("select[name='filter'");
            filterData = $filterSelect.select2("data");
            if (filterData && filterData.length > 0) {
                const $filterValueSelect = $(`#${filterData[0].slug}_id`);
                filterValueData = $filterValueSelect.select2("data");

                if (filterValueData && filterValueData.length > 0) {
                    window.Alpine.store(`${filterData[0].slug}Required`, false);
                } else {
                    window.Alpine.store(`${filterData[0].slug}Required`, true);
                    validationFailed = true;
                }
            }
        }

        // Validate granularity field
        const isGranularityRequired = window.Alpine.store(
            "isGranularityRequired"
        );
        if (isGranularityRequired) {
            const $granularitySelect = $("#granularity");
            granularityData = $granularitySelect.select2("data");
            if (granularityData && granularityData.length > 0) {
                window.Alpine.store("granularityRequired", false);
            } else {
                window.Alpine.store("granularityRequired", true);
                validationFailed = true;
            }
        }

        // Validate dates fields
        const isStartRequired = window.Alpine.store("isStartRequired");
        const isEndRequired = window.Alpine.store("isEndRequired");

        start = document.getElementById("start")?.value;
        end = document.getElementById("end")?.value;
        if (isStartRequired && isEndRequired) {
            if (start && end) {
                const startDate = new Date(start);
                const endDate = new Date(end);
                if (startDate > endDate) {
                    window.Alpine.store("startGreaterThanEnd", true);
                    validationFailed = true;
                } else {
                    window.Alpine.store("startGreaterThanEnd", false);
                }
            }
        }

        // Validate totalSumCheckbox
        const totalSumCheckbox = document.getElementById("total_sum");
        totalSum = totalSumCheckbox && totalSumCheckbox.checked ? 1 : 0;
    }

    return {
        statsData,
        filterData,
        filterValueData,
        start,
        end,
        granularityData,
        validationFailed,
        totalSum,
    };
}
