export function userCreate() {
    window.onDomReady.push(() => {
        const officeSelect = $("#office_id");
        const cardNumberInput = document.querySelector(
            'input[name="card_number"]'
        );

        window.Alpine.store("is_office_selected", false);

        const cgsCheckbox = document.querySelector('input[name="cgs"]');

        const isNursindEmployeeCheckbox = document.querySelector(
            'input[type="checkbox"][name="fake_registration_type"]'
        );

        officeSelect.on("select2:select", async () => {
            if (isNursindEmployeeCheckbox?.checked) {
                cardNumberInput.value = "N/A";
                return;
            }

            const isCgs = cgsCheckbox?.checked;
            const option = officeSelect.find(":selected");

            // Generate card number at every office select
            try {
                const response = await window.axios.get(
                    `/api/offices/${option.val()}/card/generate?cgs=${+isCgs}`
                );

                if (cardNumberInput) {
                    cardNumberInput.value = response.data;
                } else {
                    console.warn("Card number input not found");
                }
            } catch {
                console.error();
            }
        });

        cgsCheckbox?.addEventListener("click", async () => {
            if (isNursindEmployeeCheckbox?.checked) {
                cardNumberInput.value = "N/A";
                return;
            }

            const isCgs = cgsCheckbox.checked;

            const option = officeSelect.find(":selected");
            if (option && option.val()) {
                try {
                    const cardNumber = await window.user.generateCardNumber(
                        option.val(),
                        isCgs
                    );

                    if (cardNumberInput) {
                        cardNumberInput.value = cardNumber;
                    } else {
                        console.warn("Card number input not found");
                    }
                } catch {
                    console.error();
                }
            }

            const companyDutiesSelect = window.Alpine.store("company_duty_id");
            if (companyDutiesSelect) {
                companyDutiesSelect.setFilters([
                    { by: "cgs", value: [+isCgs], countFiltered: 0 },
                ]);
            }
        });

        isNursindEmployeeCheckbox?.addEventListener("click", async () => {
            const isCgs = cgsCheckbox?.checked;
            const option = officeSelect.find(":selected");

            if (isNursindEmployeeCheckbox.checked) {
                if (cardNumberInput) {
                    cardNumberInput.value = "N/A";
                } else {
                    console.warn("Card number input not found");
                }
            } else {
                if (!option || !option.val()) {
                    return;
                }

                try {
                    const cardNumber = await window.user.generateCardNumber(
                        option.val(),
                        isCgs
                    );

                    if (cardNumberInput) {
                        cardNumberInput.value = cardNumber;
                    } else {
                        console.warn("Card number input not found");
                    }
                } catch {
                    console.error();
                }
            }
        });

        window.user.initCompanyFields();
    });

    // Retrive pending form data from localstorage and populate the form
    window.onDomReady.push(() => {
        const urlParams = new URLSearchParams(location.search);

        if (urlParams.get("pending")) {
            let pendingFormData = localStorage.getItem("pendingForm");
            if (pendingFormData) {
                pendingFormData = JSON.parse(pendingFormData);

                window.utils.fillForm(pendingFormData);

                // Update form state after form is filled
                const isNursindEmployee =
                    +document.getElementById("nursindEmployee")?.checked;
                let isSuperAdmin = false;
                let currentTab = "";
                let registrationType = "";
                if (isNursindEmployee) {
                    isSuperAdmin =
                        +document.getElementById("superAdmin")?.checked;
                    currentTab = "employee";
                    registrationType = "nursind_employee";
                } else {
                    currentTab = document.getElementById("currentTab")?.value;
                    registrationType = currentTab;
                }
                const jurisdictionType =
                    document.getElementById("jurisdictionType")?.value;

                const event = new CustomEvent("update-form-states", {
                    detail: {
                        isNursindEmployee,
                        isSuperAdmin,
                        currentTab,
                        jurisdictionType,
                        registrationType,
                    },
                });
                window.dispatchEvent(event);
            }
        }
    });

    // Save form data in localstorage before submit
    document.querySelector("form")?.addEventListener("submit", (e) => {
        const data = new FormData(e.target);
        const formData = Object.fromEntries(data.entries());

        const selects = e.target.querySelectorAll("select");
        for (let select of selects) {
            const name = select.name;

            const $options = $(select).select2("data");
            if ($options && $options.length > 0) {
                formData[name] = [];

                for (let option of $options) {
                    if (option.text && option.id) {
                        formData[name].push({
                            text: option.text,
                            value: option.id,
                        });
                    }
                }
            } else {
                delete formData[name];
            }
        }

        delete formData._token;

        localStorage.setItem("pendingForm", JSON.stringify(formData));
    });

    // Jurisdiction fields
    window.onDomReady.push(window.user.initJurisdictionsFields);
}
