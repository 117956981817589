import DataTable from "datatables.net-bs5";
import Buttons from "datatables.net-buttons-bs5";

DataTable(window, window.$);
Buttons();
// Globals settings
$.extend($.fn.dataTableExt.oStdClasses, {
    sFilterInput: "form-control form-control-solid",
});

export class Datatable {
    POSITIONS = { top: "top", inline: "inline" };
    filters = [];
    lastReceivedData;

    constructor(elementId, options) {
        this.elementId = elementId;
        this.options = options;
        this.endpoint = options.modelName;
        this.dataTableNode = document.getElementById(elementId);

        this.selectedEntityId;

        // Init columns
        const cols = options.columns.map((col) => {
            return {
                name: col.data,
                data: col.data.replaceAll(".", "\\."),
                render: eval(col.render) ?? null,
            };
        });

        if (options.actions && options.actions.length > 0) {
            cols.push({
                name: options.actionsColumn,
                data: options.actionsColumn,
            });
        }

        if (options.previewColumns && options.previewColumns.length > 0) {
            cols.push({
                name: "preview",
                data: "preview",
            });
        }

        this.dataTableOptions = {
            serverSide: true,
            processing: false,
            ajax: {
                url: options.dataEndpoint,
                data: (data) => this._getCustomHttpParameters(data),
                dataFilter: (response) =>
                    this._handleCustomHttpResponse(response),
            },
            columns: cols,
            initComplete: () => {
                this._setActionsButtonsEvents(options.modelName);
            },

            order: [cols[0].data == "preview" ? 1 : 0, "asc"],
            drawCallback: this._setInlineButtonsEvents.bind(this),
            oLanguage: {
                sSearch: "Cerca:",
            },

            language: {
                paginate: {
                    previous: "Precedente",
                    next: "Successivo",
                },
                emptyTable: "Nessun dato disponibile",
                zeroRecords: "Nessun dato disponibile",
            },
            responsive: true,
            pageLength: 25,
            lengthChange: false,
            dom: 'frt<"d-flex justify-content-between align-items-center"ip>',

            ...options.customSettings,
        };

        // Add actions btn
        this.addActions(options.actions);

        // Add filters
        this.setFilters(options.filters);

        this.dataTable = $(`#${elementId}`).DataTable(this.dataTableOptions);

        Alpine.store(this.elementId, this);
    }

    addActions(actions) {
        let buttonsHtmlString = "";

        for (let action of actions) {
            if (action.position == this.POSITIONS.top) {
                this.dataTableOptions.dom =
                    'Bfrt<"d-flex justify-content-between align-items-center"ip>';
                if (!this.dataTableOptions.buttons) {
                    this.dataTableOptions.buttons = [];
                }

                const buttonOptions = {
                    text: action.name,
                    attr: { name: action.slug, class: "btn btn-success" },
                };

                if (!action.useCustomCallback) {
                    buttonOptions.action = () => {
                        if (!action.endpoint) {
                            console.warn("No endpoint specified for action");
                            return;
                        }

                        location.href = action.endpoint;
                    };
                }

                this.dataTableOptions.buttons.push(buttonOptions);
            } else if (action.position == this.POSITIONS.inline) {
                buttonsHtmlString += this._createActionButton(action);
            }
        }

        let actionsColumnIdx = -1;

        this.dataTableOptions.columnDefs = [];

        if (
            this.options.previewColumns &&
            this.options.previewColumns.length > 0
        ) {
            this.dataTableOptions.columnDefs.push({
                targets: -1,
                orderable: false,
                searchable: false,
                defaultContent:
                    '<button class="btn btn-secondary btn-sm" action="preview"><svg xmlns="http://www.w3.org/2000/svg" style="width: 15px" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" /></svg></button>',
                name: "previewBtn",
                data: null,
            });

            actionsColumnIdx = -2;
        }

        if (buttonsHtmlString) {
            this.dataTableOptions.columnDefs.push({
                targets: actionsColumnIdx,
                data: null,
                defaultContent: buttonsHtmlString,
                searchable: false,
                orderable: false,
            });
        }
    }

    setFilters(filters) {
        this.filters = filters;
    }

    resetFilters() {
        this.filters = [];
    }

    redraw() {
        this.dataTable.ajax.reload();
    }

    getLastReceivedData() {
        return this.lastReceivedData;
    }

    _setInlineButtonsEvents() {
        // Set crud operations buttons event listeners
        const actionsButtons =
            this.dataTableNode.querySelectorAll("button[action]");

        for (let button of actionsButtons) {
            const entityId = button.parentElement.parentElement.id;
            const action = button.getAttribute("action");

            let dtRow = undefined;
            if (action == "preview") {
                const row = button.parentElement.parentElement;
                dtRow = this.dataTable.row(row);
                dtRow.child(this.options.previewPanel(dtRow.data()));
                dtRow.child.hidden = true;
            }

            button.addEventListener("click", () => {
                switch (action) {
                    case "delete":
                        this.selectedEntityId = entityId;
                        break;
                    case "show":
                        location.href = new URL(
                            `${this.endpoint}/${entityId}`,
                            location.origin
                        );
                        break;
                    case "preview":
                        if (!dtRow) {
                            break;
                        }

                        if (dtRow.child.hidden) {
                            dtRow.child.show();
                            dtRow.child.hidden = false;
                            button.innerHTML =
                                '<svg xmlns="http://www.w3.org/2000/svg" style="width: 15px" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" /></svg>';
                        } else {
                            dtRow.child.hide();
                            dtRow.child.hidden = true;
                            button.innerHTML =
                                '<svg xmlns="http://www.w3.org/2000/svg" style="width: 15px" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" /></svg>';
                        }
                        break;
                    default:
                        location.href = new URL(
                            `${this.endpoint}/${entityId}/${action}`,
                            location.origin
                        );
                        break;
                }
            });
        }
    }

    _setActionsButtonsEvents(endpoint) {
        this._setInlineButtonsEvents();
        // Set delete modal confirm button listener
        const confirmBtn = document.querySelector("button[confirm]");
        if (confirmBtn) {
            confirmBtn.addEventListener("click", async () => {
                try {
                    await axios.delete(
                        new URL(
                            `${endpoint}/${this.selectedEntityId}`,
                            location.origin
                        )
                    );

                    toastr.success("Elemento eliminato con successo");

                    this.redraw();
                } catch {
                    toastr.error("Impossibile eliminare elemento");
                }

                const deleteModal = bsModal.getInstance(
                    document.getElementById(`delete_${this.elementId}`)
                );
                deleteModal.hide();
            });
        }
    }

    _createActionButton(action) {
        const button = document.createElement("button");
        button.innerHTML = action.name;
        button.setAttribute("action", action.slug);
        button.classList.add("btn", "btn-secondary", "btn-sm", "me-1");

        if (action.slug == "delete") {
            button.setAttribute("data-bs-toggle", "modal");
            button.setAttribute("data-bs-target", `#delete_${this.elementId}`);
        }

        return button.outerHTML;
    }

    _getCustomHttpParameters(legacyParameters) {
        const order = [];
        for (let i = 0; i < legacyParameters.order.length; i++) {
            order.push({
                by: legacyParameters.columns[
                    legacyParameters.order[i].column
                ].data.replaceAll("\\.", "."),
                direction: legacyParameters.order[i].dir,
            });
        }

        // Add id column
        legacyParameters.columns.unshift({ name: "id", data: "id" });
        // Remove actions column
        legacyParameters.columns = legacyParameters.columns.filter(
            (column) => column.name != this.options.actionsColumn
        );
        // Add preview columns
        if (
            this.options.previewColumns &&
            this.options.previewColumns.length > 0
        ) {
            legacyParameters.columns = legacyParameters.columns.concat(
                this.options.previewColumns
            );

            // Remove duplicates
            legacyParameters.columns = legacyParameters.columns.filter(
                (col, idx, self) => {
                    return (
                        idx ===
                        self.findIndex((value) => value.data == col.data)
                    );
                }
            );

            // Remove preview columm
            legacyParameters.columns = legacyParameters.columns.filter(
                (col) => {
                    return col.data != "preview";
                }
            );
        }

        return {
            draw_counter: legacyParameters.draw,
            columns: legacyParameters.columns.map((entry) =>
                entry.data.replaceAll("\\.", ".")
            ),
            start: legacyParameters.start,
            length: legacyParameters.length,
            term: legacyParameters.search.value,
            order: order,
            filters: this.filters,
        };
    }

    _handleCustomHttpResponse(response) {
        response = JSON.parse(response);

        const formattedData = [];
        for (let i = 0; i < response.data.length; ++i) {
            const attributes = Object.keys(response.data[i]);
            const tableEntry = {};
            for (let attribute of attributes) {
                let key = attribute == "id" ? "DT_RowId" : attribute;
                tableEntry[key] = response.data[i][attribute];
            }

            formattedData.push(tableEntry);
        }

        const formattedResponse = {
            draw: response.draw_counter,
            recordsTotal: response.total,
            recordsFiltered: response.filtered,
            data: formattedData,
            error: response.error ?? "",
        };

        this.lastReceivedData = formattedData;

        return JSON.stringify(formattedResponse);
    }
}
