import { Loader } from "google-maps";

export class Map {
    static modes = {
        static: 0,
        interactive: 1,
    };

    static errors = {
        NO_PLACE_SELECTED: "Selezionare un indirizzo prima di inviare.",
        INVALID_MODE: "Invalid mode for componente map",
        NO_API_KEY_PROVIDED: "Provide a valid api key",
        NO_SEARCH_BAR_ID_PROVIDED: "No search bar id provided",
        NO_MAP_ID_PROVIDED: "No map id provided",
    };

    startingPosition = {
        center: { lat: 41.9027835, lng: 12.4963655 },
        zoom: 5,
    };

    constructor(options) {
        if (!options.key) {
            throw new Error(Map.errors.NO_API_KEY_PROVIDED);
        }

        this.marker;

        const loaderOptions = { libraries: ["places"] };
        const loader = new Loader(options.key, loaderOptions);

        const areCoordinatesDefined =
            options.markerLocation?.[0] && options.markerLocation?.[1];

        (async () => {
            const google = await loader.load();

            if (options.mode === Map.modes.interactive) {
                if (!options.mapId) {
                    throw new Error(Map.errors.NO_MAP_ID_PROVIDED);
                }

                this.map = new google.maps.Map(
                    document.getElementById(options.mapId),
                    {
                        ...this.startingPosition,
                        streetViewControl: false,
                    }
                );

                this.geocoder = new google.maps.Geocoder();

                if (!options.searchBarId) {
                    throw new Error(Map.errors.NO_SEARCH_BAR_ID_PROVIDED);
                }

                const searchBar = document.getElementById(options.searchBarId);
                this.autocomplete = new google.maps.places.Autocomplete(
                    searchBar,
                    {
                        componentRestrictions: { country: ["IT"] },
                        fields: [
                            "formatted_address",
                            "geometry",
                            "address_components",
                        ],
                        types: ["address"],
                    }
                );

                if (!options.onMarkerPlaced) {
                    options.onMarkerPlaced = undefined;
                }

                this.autocomplete.addListener("place_changed", () =>
                    this.setMarker(
                        this.autocomplete.getPlace(),
                        true,
                        true,
                        options.onMarkerPlaced
                    )
                );

                this.map.addListener("click", (e) => {
                    this.geocoder
                        .geocode({ location: e.latLng })
                        .then((response) => {
                            if (response.results[0]) {
                                //debugger;
                                this.setMarker(
                                    response.results[0],
                                    true,
                                    true,
                                    options.onMarkerPlaced
                                );
                            }
                        });
                });

                if (areCoordinatesDefined) {
                    this.setMarker(
                        {
                            geometry: {
                                location: {
                                    lat: options.markerLocation[0],
                                    lng: options.markerLocation[1],
                                },
                            },
                        },
                        true,
                        false,
                        null,
                        14
                    );
                }
            } else if (options.mode === Map.modes.static) {
                if (!areCoordinatesDefined) {
                    throw new Error(Map.errors.NO_PLACE_SELECTED);
                }

                this.map = new google.maps.Map(
                    document.getElementById(options.mapId),
                    { streetViewControl: false }
                );

                this.setMarker(
                    {
                        geometry: {
                            location: {
                                lat: options.markerLocation[0],
                                lng: options.markerLocation[1],
                            },
                        },
                    },
                    false,
                    false,
                    null,
                    14
                );
            } else {
                throw new Error(Map.errors.INVALID_MODE);
            }
        })();
    }

    setMarker(
        place,
        draggable,
        loadPlaceData,
        callback = undefined,
        zoom = 17
    ) {
        if (place) {
            // Focus map on place location
            if (place.geometry.viewport) {
                this.map.fitBounds(place.geometry.viewport);
            } else {
                this.map.setCenter(place.geometry.location);
                this.map.setZoom(zoom);
            }

            // Delete current marker
            if (this.marker) {
                this.marker.setMap(null);
            }

            // Create marker
            this.marker = new google.maps.Marker();
            this.marker.setMap(this.map);
            this.marker.setPosition(place.geometry.location);
            this.marker.setVisible(true);
            if (draggable) {
                this.marker.setDraggable(true);
            }

            // Add draggable events
            this.marker.addListener("dragend", () => {
                this.geocoder
                    .geocode({ location: this.marker.getPosition() })
                    .then((response) => {
                        if (response.results[0]) {
                            this.loadPlaceData(response.results[0]);
                            if (callback) {
                                callback(this.marker);
                            }
                        }
                    });
            });

            if (loadPlaceData) {
                this.loadPlaceData(place);
            }

            if (callback) {
                callback(this.marker);
            }
        } else {
            this.showErrorMessage(Map.errors.NO_PLACE_SELECTED);
        }
    }

    loadPlaceData(place) {
        this.marker.formattedAddress = place.formatted_address;
        this.marker.placeLocation = place.geometry.location;

        this.marker.region = place.address_components.filter((value) =>
            value.types.includes("administrative_area_level_1")
        )?.[0];

        this.marker.province = place.address_components.filter((value) =>
            value.types.includes("administrative_area_level_2")
        )?.[0];

        this.marker.city =
            place.address_components.filter((value) =>
                value.types.includes("locality")
            )?.[0] ??
            place.address_components.filter((value) =>
                value.types.includes("administrative_area_level_3")
            )?.[0];

        this.marker.cap = place.address_components.filter((value) =>
            value.types.includes("postal_code")
        )?.[0];
    }
}
