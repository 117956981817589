export function fillForm(pendingFormData) {
    for (let field of Object.keys(pendingFormData)) {
        let element = document.querySelector(`*[name^='${field}']`);

        if (element) {
            switch (element.tagName) {
                case "INPUT":
                    if (element.type == "checkbox" || element.type == "radio") {
                        if (
                            element.getAttribute("value") !=
                            pendingFormData[field]
                        ) {
                            element = document.querySelector(
                                `input[name="${field}"][value="${pendingFormData[field]}"]`
                            );
                        }

                        element.checked = true;
                    } else if (element.type == "file") {
                        continue;
                    } else {
                        if (Array.isArray(pendingFormData[field])) {
                            const elements = document.querySelectorAll(
                                `input[name^='${field}']`
                            );

                            for (let i = 0; i < elements.length; i++) {
                                elements[i].value = pendingFormData[field][i];
                            }
                        } else {
                            element.value = pendingFormData[field];
                        }
                    }
                    break;
                case "SELECT": {
                    let optionsData = [];
                    if (Array.isArray(pendingFormData[field])) {
                        optionsData = pendingFormData[field];
                    } else {
                        optionsData.push(pendingFormData[field]);
                    }

                    for (let value of optionsData) {
                        const optionExist = element.querySelector(
                            `option[value="${value.value}"]`
                        );

                        if (!optionExist) {
                            const option = document.createElement("option");
                            option.value = value.value;
                            option.innerText = value.text;

                            element.appendChild(option);
                        }
                    }

                    const values = optionsData.map((option) =>
                        option.value.toString()
                    );

                    $(element).val(values).trigger("change");
                    break;
                }
                case "TEXTAREA":
                    element.value = pendingFormData[field];
                    break;
                default:
                    console.warn(`${field} not an input, select or textarea.`);
                    break;
            }
        }
    }
}

export function resetForm(excludeList = []) {
    excludeList.push("_token"); // Do not reset token value

    const selects = document.getElementsByTagName("select");
    for (let select of selects) {
        if (!excludeList.includes(select.name)) {
            $(select).val(null).trigger("change");
        }
    }

    const textareas = document.getElementsByTagName("textarea");
    for (let textarea of textareas) {
        if (!excludeList.includes(textarea.name)) {
            textarea.value = "";
        }
    }

    const inputs = document.getElementsByTagName("input");
    for (let input of inputs) {
        if (!excludeList.includes(input.name)) {
            if (input.type == "checkbox" || input.type == "radio") {
                input.checked = false;
            } else {
                input.value = "";
            }
        }
    }
}

export function urlContainsParameter(parameter) {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get(parameter);
}

export function createApiUrl(base, drawCounter, columns, order, filters) {
    let url = `${base}?draw_counter=${drawCounter}`;

    // Add columns
    for (let column of columns) {
        url += `&columns[]=${column}`;
    }

    // Add order rules
    for (let idx = 0; idx < order.length; idx++) {
        url += `&order[${idx}][by]=${order.by}&order[${idx}][direction]=${order.direction}`;
    }

    // Add filters
    for (let idx = 0; idx < filters.length; idx++) {
        url += `&filters[${idx}][by]=${filters[idx].by}`;

        for (let value of filters[idx].value) {
            url += `&filters[${idx}][value][]=${value}`;
        }

        url += `&filters[${idx}][countFiltered]=${filters[idx].countFiltered}`;
    }

    return encodeURI(url);
}

export function showToastAndReload(message, mode = "success", timeout = 800) {
    window.toastr.options.onHidden = () => {
        window.location.reload();
    };

    toastr.options.timeOut = timeout;

    if (mode == "success") {
        window.toastr.success(message);
    } else {
        window.toastr.error(message);
    }
}
