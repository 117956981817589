export async function destroy(url, element) {
    element.setAttribute("disabled", "disabled");
    try {
        const response = await window.axios.delete(url);
        const data = await response.data;
        window.location.href = data.redirect;
    } catch {
        window.utils.showToastAndReload(
            "Errore durante l'eliminazione dell'elemento"
        );
    }
}
