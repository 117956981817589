export default (querySelectId, userRole, formId) => ({
    queryName: "",
    querySelectId: querySelectId,
    showAlert: false,
    showingAlert: false,
    userRole: userRole,
    formId: formId,
    form: undefined,
    submitBtnDisabled: true,
    init() {
        this.form = document.getElementById(this.formId);

        this.$refs.modal.addEventListener("show.bs.modal", async () => {
            const querySelect = Alpine.store(this.querySelectId);
            const selected = querySelect.$select.select2("data");
            this.queryName = selected[0]?.text ?? "Tutti gli iscritti";
            if (this.userRole === "admin" || this.userRole === "superAdmin") {
                this.submitBtnDisabled = false;
                return;
            }

            if (
                !selected.length &&
                this.userRole !== "admin" &&
                this.userRole !== "superAdmin"
            ) {
                this.showAlert = true;
                this.submitBtnDisabled = false;
                return;
            }

            let showAlertRes;
            try {
                showAlertRes = await window.axios.get(
                    `/api/queries/${selected[0].id}/jurisdiction`
                );
            } catch (error) {
                window.Noty.error("Errore riprova piu tardi");
                setTimeout(() => {
                    window.location.reload();
                }, 5000);

                return;
            }

            this.showAlert = await showAlertRes.data["has_restricted_users"];

            this.submitBtnDisabled = false;
        });

        this.$refs.modal.addEventListener("hide.bs.modal", () => {
            this.showingAlert = false;
            this.showAlert = false;
            this.submitBtnDisabled = true;
        });
    },

    submitOrShowAlert() {
        if (this.showAlert) {
            this.showingAlert = true;
            return;
        }

        const modalInstance = window.bsModal.getInstance(this.$refs.modal);

        console.log("sent!");
        this.form.submit();
        modalInstance.hide();
    },
});
