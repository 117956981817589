export function onMarkerPlaced(marker) {
    console.log(marker);

    const addressField = document.getElementsByName("address")?.[0];
    const regionField = document.getElementsByName("region")?.[0];
    const provinceField = document.getElementsByName("province")?.[0];
    const cityField = document.getElementsByName("city")?.[0];
    const zipCodeField = document.getElementsByName("zipcode")?.[0];
    const latitudeField = document.getElementsByName("latitude")?.[0];
    const longitudeField = document.getElementsByName("longitude")?.[0];

    const allFieldsFound =
        addressField &&
        regionField &&
        provinceField &&
        cityField &&
        zipCodeField &&
        latitudeField &&
        longitudeField;

    if (!allFieldsFound) {
        console.warn("One or more map fields not found");
        return;
    }

    addressField.value = marker.formattedAddress ?? "";
    regionField.value = marker.region?.long_name ?? "";
    provinceField.value = marker.province?.short_name ?? "";
    cityField.value = marker.city?.long_name ?? "";
    zipCodeField.value = marker.cap?.long_name ?? "";
    latitudeField.value = marker.getPosition().lat() ?? "";
    longitudeField.value = marker.getPosition().lng() ?? "";
}
