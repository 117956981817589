import { getOne } from "../query";

const FILTERS = {
    active: [
        {
            by: "membership_disabled_at",
            value: ["null"],
            countFiltered: false,
        },
    ],

    pending: [
        {
            by: "information_confirmed_at",
            value: ["null"],
            countFiltered: false,
        },
        {
            by: "membership_disabled_at",
            value: ["null"],
            countFiltered: false,
        },
    ],
    disabled: [
        {
            by: "membership_disabled_at",
            value: ["not null"],
            countFiltered: false,
        },
    ],
};

export function userIndex() {
    const queriesSelect = document.getElementById("query_id");
    const table = document.getElementById("users_table");

    if (table) {
        // Filter table on filter selected
        if (queriesSelect) {
            // On select set filter to datatable
            $(queriesSelect).on("select2:select", async (e) => {
                const datatable = Alpine.store(table.id);

                if (!datatable) {
                    console.warn("Datatable not found");
                    return;
                }

                // Get query params and check if cgs in present
                const urlSearchParams = new URLSearchParams(
                    window.location.search
                );
                const params = Object.fromEntries(urlSearchParams.entries());

                const query = await getOne(e.params.data.id);
                if ("cgs" in params) {
                    query.rule.cgs = [true];
                } else {
                    query.rule.cgs = [false];
                }

                datatable.setFilters(query.filters(["cgs"]));
                datatable.redraw();

                resetFiltersFields();
            });

            // On unselect remove filters from datatable
            $(queriesSelect).on("select2:unselect", async () => {
                const datatable = Alpine.store(table.id);
                if (!datatable) {
                    console.warn("Datatable not found");
                    return;
                }

                datatable.resetFilters();
                datatable.redraw();
            });
        } else {
            console.warn("Select2 component for queries not found");
        }

        // Export data button
        Alpine.nextTick(() => {
            const exportButton = document.getElementsByName("export")?.[0];
            if (exportButton) {
                exportButton.addEventListener("click", async () => {
                    const datatable = Alpine.store(table.id);
                    if (!datatable) {
                        console.warn("Datatable not found");
                        return;
                    }

                    // Get filters make api request and get users id
                    const filters = JSON.parse(
                        JSON.stringify(datatable.filters)
                    );

                    try {
                        // Show permanets toast
                        window.toastr.info(
                            "Attendere la generazione del file",
                            "Esportazione in corso...",
                            { timeOut: 0, extendedTimeOut: 0 }
                        );

                        let url = "/users/export?";

                        for (let idx = 0; idx < filters.length; idx++) {
                            if (idx !== 0) {
                                url += "&";
                            }

                            url += `filters[${idx}][by]=${filters[idx].by}`;

                            for (let value of filters[idx].value) {
                                url += `&filters[${idx}][value][]=${value}`;
                            }

                            url += `&filters[${idx}][countFiltered]=${filters[idx].countFiltered}`;
                        }

                        location.href = url;

                        window.toastr.clear();

                        // Remove toast
                    } catch (error) {
                        const message =
                            error.response.data.message ?? error.response.data;
                        toastr.error(message);

                        setTimeout(window.toastr.clear, 600);
                    }
                });
            } else {
                console.warn("Export button not found");
            }

            const insuranceExportButton =
                document.getElementsByName("export/insurance")?.[0];

            if (insuranceExportButton) {
                insuranceExportButton.addEventListener("click", async () => {
                    try {
                        window.toastr.info(
                            "Attendere la generazione del file",
                            "Esportazione in corso...",
                            { timeOut: 0, extendedTimeOut: 0 }
                        );

                        location.href = "/users/export/insurance";

                        window.toastr.clear();
                    } catch (error) {
                        const message =
                            error.response.data.message ?? error.response.data;
                        toastr.error(message);

                        setTimeout(window.toastr.clear, 600);
                    }
                });
            } else {
                console.log("Insurance export button not found");
            }
        });

        // Set filters based on navigation state
        const filtersNav = document.getElementById("table_filters_nav");
        const buttons = filtersNav?.querySelectorAll("button");

        for (let button of buttons) {
            button.addEventListener("click", () => {
                const datatable = Alpine.store(table.id);
                if (!datatable) {
                    console.warn("Datatable not found");
                    return;
                }

                const querySelect = $("#query_id");
                const transfersTableContainer = document.getElementById(
                    "transfers_table_container"
                );

                const filterType = button.getAttribute("filter");
                let usersTableFilters = [];
                let transferDataTableFilters = [
                    {
                        by: "approved_at",
                        value: ["null"],
                        countFiltered: false,
                    },
                ];

                datatable.resetFilters();

                switch (filterType) {
                    case "overview":
                        querySelect.prop("disabled", false);
                        break;
                    case "transfers":
                        querySelect.prop("disabled", true);
                        querySelect.val(null).trigger("change");

                        if (
                            transfersTableContainer.classList.contains("d-none")
                        ) {
                            transfersTableContainer.classList.remove("d-none");
                            if (
                                !table.parentNode.classList.contains("d-none")
                            ) {
                                table.parentNode.classList.add("d-none");
                            }
                        }
                        break;
                    case "active":
                        usersTableFilters = usersTableFilters.concat(
                            FILTERS.active
                        );
                        break;
                    case "pending":
                        usersTableFilters = usersTableFilters.concat(
                            FILTERS.pending
                        );
                        break;
                    case "disabled":
                        usersTableFilters = usersTableFilters.concat(
                            FILTERS.disabled
                        );
                        break;
                }

                const isCgsFilter = {
                    by: filterType != "transfers" ? "cgs" : "user.cgs",
                    value: [
                        window.utils.urlContainsParameter("cgs") ? true : false,
                    ],
                    countFiltered: false,
                };

                usersTableFilters.push(isCgsFilter);
                transferDataTableFilters.push(isCgsFilter);

                if (filterType !== "transfers") {
                    if (filterType !== "overview") {
                        querySelect.prop("disabled", true);
                        querySelect.val(null).trigger("change");
                    }

                    datatable.resetFilters();
                    datatable.setFilters(usersTableFilters);
                    datatable.redraw();

                    if (table.parentElement.classList.contains("d-none")) {
                        table.parentElement.classList.remove("d-none");
                        if (
                            !transfersTableContainer.classList.contains(
                                "d-none"
                            )
                        ) {
                            transfersTableContainer.classList.add("d-none");
                        }
                    }
                } else {
                    const transfersTable =
                        transfersTableContainer.querySelector("table");
                    const transfersTableIstance = window.Alpine.store(
                        transfersTable?.id
                    );
                    if (transfersTableIstance) {
                        transfersTableIstance.resetFilters();
                        transfersTableIstance.setFilters(
                            transferDataTableFilters
                        );
                        transfersTableIstance.redraw();
                    } else {
                        console.warn("Transfers table not found");
                    }
                }

                if (!button.classList.contains("active")) {
                    const activeBtn =
                        filtersNav.getElementsByClassName("active")?.[0];

                    if (activeBtn) {
                        activeBtn.classList.remove("active");
                    }

                    button.classList.add("active");
                }

                resetFiltersFields();
            });
        }
    } else {
        console.warn("Table not found.");
    }

    // Initialise chart
    // Capire come prendersi gli utenti con un trasferimento in pending
    (async () => {
        await window.statistics.userOverview(
            "chart_container",
            true,
            null,
            null
        );
    })();

    // Set added this week counter value
    (async () => {
        const addedThisWeek = await window.statistics.addedThisWeek(true);

        const addedThisWeekCounter =
            document.getElementById("this_week_counter");

        addedThisWeekCounter.innerText = addedThisWeek;
    })();
}

export function generatePreviewPanel(userData) {
    // Transform all null and undefined values to empty string
    for (let key of Object.keys(userData)) {
        if (userData[key] === null || userData[key] === undefined) {
            userData[key] = "";
        }
    }

    // Elements
    const TITLE = document.createElement("span");
    TITLE.classList.add("fw-bolder", "fs-7", "text-gray-600", "text-uppercase");

    const FIELD = document.createElement("span");
    FIELD.classList.add("fw-bolder", "fs-7");

    const SECTION = document.createElement("div");
    SECTION.classList.add("d-flex", "flex-column");

    const ROW = SECTION.cloneNode();
    ROW.classList.add("gap-5");

    SECTION.classList.add("gap-1");

    const appendMany = (parent, children) => {
        children.forEach((child) => parent.appendChild(child));
    };

    //* Dati anagrafici
    const container = document.createElement("div");
    container.classList.add("d-flex", "justify-content-between");

    const fistRow = ROW.cloneNode();
    let cell = SECTION.cloneNode();

    let fields = [];

    const personalDataTitle = TITLE.cloneNode();
    personalDataTitle.innerHTML = "Dati anagrafici";
    fields.push(personalDataTitle);

    const taxCode = FIELD.cloneNode();
    taxCode.innerHTML = "Codice fiscale: " + userData.tax_code;
    fields.push(taxCode);

    const gender = FIELD.cloneNode();
    gender.innerHTML = "Sesso: " + userData["gender.symbol"];
    fields.push(gender);

    appendMany(cell, fields);

    fistRow.appendChild(cell);

    container.appendChild(fistRow);

    //* Luogo di nascita
    fields = [];
    cell = SECTION.cloneNode();

    const birthPlaceTitle = TITLE.cloneNode();
    birthPlaceTitle.innerHTML = "Luogo di nascita";
    fields.push(birthPlaceTitle);

    const birthDate = FIELD.cloneNode();
    birthDate.innerHTML = "Data di nascita: " + userData.birth_date;
    fields.push(birthDate);

    const birthCity = FIELD.cloneNode();
    birthCity.innerHTML = "Città di nascita: " + userData.birth_city;
    fields.push(birthCity);

    appendMany(cell, fields);
    fistRow.appendChild(cell);
    container.appendChild(fistRow);

    //* Luogo di residenza
    cell = SECTION.cloneNode();
    fields = [];

    const residenceAddressTitle = TITLE.cloneNode();
    residenceAddressTitle.innerHTML = "Luogo di residenza";
    fields.push(residenceAddressTitle);

    const residenceAddress = FIELD.cloneNode();
    residenceAddress.innerHTML = userData.residence_address;
    fields.push(residenceAddress);

    appendMany(cell, fields);
    fistRow.appendChild(cell);

    //* Contatti
    cell = SECTION.cloneNode();
    fields = [];

    const contactsTitle = TITLE.cloneNode();
    contactsTitle.innerHTML = "Contatti";
    fields.push(contactsTitle);

    const phoneNumber = FIELD.cloneNode();
    phoneNumber.innerHTML = "Telefono: " + userData.first_phone_number;
    fields.push(phoneNumber);

    appendMany(cell, fields);
    fistRow.appendChild(cell);

    const secondRow = ROW.cloneNode();

    //* Dati aziendali
    cell = SECTION.cloneNode();
    fields = [];

    const companyDataTitle = TITLE.cloneNode();
    companyDataTitle.innerHTML = "Dati aziendali";
    fields.push(companyDataTitle);

    const companyName = FIELD.cloneNode();
    companyName.innerHTML =
        "Azienda ospedaliera: " + userData["membershipDetails.company.name"];
    fields.push(companyName);

    const unitName = FIELD.cloneNode();
    unitName.innerHTML =
        "Presidio ospedaliero: " + userData["membershipDetails.unit.name"];
    fields.push(unitName);

    const companyProvince = FIELD.cloneNode();
    companyProvince.innerHTML =
        "Località: " + userData["membershipDetails.company.province.name"];
    fields.push(companyProvince);

    appendMany(cell, fields);
    secondRow.appendChild(cell);
    container.appendChild(secondRow);

    //* Dettagli assunzione
    cell = SECTION.cloneNode();
    fields = [];

    const employmentDetailsTitle = TITLE.cloneNode();
    employmentDetailsTitle.innerHTML = "Dettagli assunzione";
    fields.push(employmentDetailsTitle);

    const membershipLength = FIELD.cloneNode();
    membershipLength.innerHTML =
        "Tipo iscrizione: " +
        (userData["membershipDetails.membership_length"] === 1
            ? "Trattenuta"
            : userData["membershipDetails.membership_length"]);

    fields.push(membershipLength);

    const companyNumber = FIELD.cloneNode();
    companyNumber.innerHTML =
        "Codice matricola: " + userData["membershipDetails.company_number"];
    fields.push(companyNumber);

    const nextPaymentDate = FIELD.cloneNode();
    nextPaymentDate.innerHTML =
        "Prossimo pagamento: " + userData.next_payment_date;
    fields.push(nextPaymentDate);

    const ward = FIELD.cloneNode();
    ward.innerHTML = "Reparto: " + userData["membershipDetails.ward.name"];
    fields.push(ward);

    const companyDuty = FIELD.cloneNode();
    companyDuty.innerHTML =
        "Impiego: " + userData["membershipDetails.companyDuty.name"];
    fields.push(companyDuty);

    appendMany(cell, fields);
    secondRow.appendChild(cell);

    const thirdRow = ROW.cloneNode();

    //* Dati sindacali
    cell = SECTION.cloneNode();
    fields = [];

    const unionDataTitle = TITLE.cloneNode();
    unionDataTitle.innerHTML = "Dati sindacali";
    fields.push(unionDataTitle);

    const registeredOn = FIELD.cloneNode();
    registeredOn.innerHTML =
        "Data iscrizione: " + (userData.registered_on ?? "N/A");
    fields.push(registeredOn);

    const officeName = FIELD.cloneNode();
    officeName.innerHTML = "Segreteria: " + userData["office.name"];
    fields.push(officeName);

    const pastUnionName = FIELD.cloneNode();
    pastUnionName.innerHTML = "Disdetta da: " + userData["pastUnion.name"];
    fields.push(pastUnionName);

    const unionDuty = FIELD.cloneNode();
    unionDuty.innerHTML = "Funzione: " + userData["membershipType.name"];
    fields.push(unionDuty);

    const cgs = FIELD.cloneNode();
    cgs.innerHTML = "CGS: " + (userData["cgs"] ? "Sì" : "No");
    fields.push(cgs);

    appendMany(cell, fields);
    thirdRow.appendChild(cell);
    container.appendChild(thirdRow);

    //* Tessera
    cell = SECTION.cloneNode();
    fields = [];

    const cardTitle = TITLE.cloneNode();
    cardTitle.innerHTML = "Tessera";
    fields.push(cardTitle);

    const cardCreatedOn = FIELD.cloneNode();
    cardCreatedOn.innerHTML =
        "Data creazione tessera: " + userData.card_created_at;
    fields.push(cardCreatedOn);

    const cardPrinted = FIELD.cloneNode();
    cardPrinted.innerHTML =
        "Scansione tessera: " + (userData.card_printed ? "Si" : "No");
    fields.push(cardPrinted);

    const state = FIELD.cloneNode();
    state.innerHTML =
        "Stato: " +
        (userData.membership_disabled_at ? "Disattivata" : "Attiva");
    fields.push(state);

    appendMany(cell, fields);
    thirdRow.appendChild(cell);

    //* Assicurazione
    cell = SECTION.cloneNode();
    fields = [];

    const insuranceTitle = TITLE.cloneNode();
    insuranceTitle.innerHTML = "Assicurazione";
    fields.push(insuranceTitle);

    const insurancePolicyAccepted = FIELD.cloneNode();
    insurancePolicyAccepted.innerHTML =
        "Consenso assicurazione: " +
        (userData.insurance_policy_accepted ? "Si" : "No");
    fields.push(insurancePolicyAccepted);

    const insuranceState = FIELD.cloneNode();
    insuranceState.innerHTML =
        "Assicurazione: " + userData["insuranceState.name"];
    fields.push(insuranceState);

    appendMany(cell, fields);
    thirdRow.appendChild(cell);

    return container;
}

export function setUserStateValue(data, type, row, meta) {
    if (row.membership_disabled_at) {
        return "<span class='badge badge-light-danger'> Disattivato </span>";
    } else {
        return "<span class='badge badge-light-success'> Attivo </span>";
    }
}

export function loadFilters() {
    const region = $("#region_id").select2("data");
    const company = $("#company_id").select2("data");
    const unit = $("#unit_id").select2("data");
    const duty = $("#union_duty_id").select2("data");
    const office = $("#office_id").select2("data");
    const surname = $("#surname").val();

    let filters = [];

    if (region && region.length > 0) {
        filters.push({
            by: "office.provinceJurisdiction.region_id",
            value: [region[0].id],
            countFiltered: false,
        });
    }
    if (company && company.length > 0) {
        filters.push({
            by: "membershipDetails.company_id",
            value: [company[0].id],
            countFiltered: false,
        });
    }
    if (unit && unit.length > 0) {
        filters.push({
            by: "membershipDetails.unit_id",
            value: [unit[0].id],
            countFiltered: false,
        });
    }
    if (duty && duty.length > 0) {
        filters.push({
            by: "membershipDetails.union_duty_id",
            value: [duty[0].id],
            countFiltered: false,
        });
    }
    if (office && office.length > 0) {
        filters.push({
            by: "office_id",
            value: [office[0].id],
            countFiltered: false,
        });
    }
    if (surname) {
        filters.push({
            by: "surname",
            value: [surname],
            countFiltered: false,
            fullText: true,
        });
    }

    filters.push({
        by: "cgs",
        value: [window.utils.urlContainsParameter("cgs") ? true : false],
        countFiltered: false,
    });

    const active = document.getElementsByClassName(
        "btn btn-active-light-primary btn-text-gray-600 active"
    )?.[0];

    if (active) {
        const filterType = active.getAttribute("filter");

        switch (filterType) {
            case "active":
                filters = filters.concat(FILTERS.active);
                break;
            case "disabled":
                filters = filters.concat(FILTERS.disabled);
                break;
            case "pending":
                filters = filters.concat(FILTERS.pending);
                break;
        }
    }

    // Get table instance
    const tableIstance = window.Alpine.store("users_table");
    tableIstance.setFilters(filters);
    tableIstance.redraw();

    // Reset query select
    // Forse va fatto all'inizio dato che questo triggera un clear forse
    $("#query_id").val(null).trigger("change");
}

export function resetFilters() {
    resetFiltersFields();

    let filters = [];
    filters.push({
        by: "cgs",
        value: [window.utils.urlContainsParameter("cgs") ? true : false],
        countFiltered: false,
    });

    const active = document.getElementsByClassName(
        "btn btn-active-light-primary btn-text-gray-600 active"
    )?.[0];

    if (active) {
        const filterType = active.getAttribute("filter");

        switch (filterType) {
            case "active":
                filters = filters.concat(FILTERS.active);
                break;
            case "disabled":
                filters = filters.concat(FILTERS.disabled);
                break;
            case "pending":
                filters = filters.concat(FILTERS.pending);
                break;
        }
    }
    const tableIstance = window.Alpine.store("users_table");
    tableIstance.setFilters(filters);
    tableIstance.redraw();
}

function resetFiltersFields() {
    $("#region_id").val(null).trigger("change");
    $("#company_id").val(null).trigger("change");
    $("#unit_id").val(null).trigger("change");
    $("#union_duty_id").val(null).trigger("change");
    $("#office_id").val(null).trigger("change");
    $("#surname").val("");
}
