export async function loadUsersChart(officeId) {
    await window.statistics.userOverview(
        "users_chart",
        false,
        "office",
        officeId
    );
}

export async function loadUsersByCompanies(officeId, containerId) {
    const data = await window.statistics.usersByCompaniesCount(
        false,
        "office",
        officeId
    );

    const container = document.getElementById(containerId);
    // Collumns
    const companiesNamesColumn = document.createElement("div");
    companiesNamesColumn.classList.add("d-flex", "flex-column", "gap-10");
    container.appendChild(companiesNamesColumn);

    const columnHeader = document.createElement("span");
    columnHeader.classList.add(
        "fw-bolder",
        "text-gray-600",
        "text-uppercase",
        "fs-5"
    );

    const companiesNamesHeader = columnHeader.cloneNode();
    companiesNamesHeader.innerText = "azienda";
    companiesNamesColumn.appendChild(companiesNamesHeader);

    const countsColumnContainer = document.createElement("div");
    countsColumnContainer.classList.add("d-flex", "gap-15", "ms-auto");
    container.appendChild(countsColumnContainer);

    const enabledColumn = document.createElement("div");
    enabledColumn.classList.add("d-flex", "flex-column", "gap-10");
    countsColumnContainer.appendChild(enabledColumn);

    const enabledHeader = columnHeader.cloneNode();
    enabledHeader.innerText = "Attivi";
    enabledColumn.appendChild(enabledHeader);

    const disabledColumn = document.createElement("div");
    disabledColumn.classList.add("d-flex", "flex-column", "gap-10");
    countsColumnContainer.appendChild(disabledColumn);

    const disabledHeader = columnHeader.cloneNode();
    disabledHeader.innerText = "Disattivi";
    disabledColumn.appendChild(disabledHeader);

    const textElement = document.createElement("span");
    textElement.classList.add("fw-bold");

    for (let company of data) {
        const companyName = document.createElement("a");
        companyName.textContent = company.name;
        if (company.id) {
            companyName.href = "/companies/" + company.id;
        }
        companiesNamesColumn.appendChild(companyName);

        const enabledCount = textElement.cloneNode();
        enabledCount.textContent = company.enabled;
        enabledColumn.appendChild(enabledCount);

        const disabledCount = textElement.cloneNode();
        disabledCount.textContent = company.disabled;
        disabledColumn.appendChild(disabledCount);
    }
}
