export function beforeSend(evt) {
    evt.preventDefault();
    window.toastr.info(
        "Invio in corso...",
        "Attendere la fine dell'operazione",
        { timeOut: 0, extendedTimeOut: 0 }
    );

    evt.target.submit();
}
