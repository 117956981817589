export function userEdit() {
    window.user.initJurisdictionsFields();
}

export function isNursindEmployee(membershipType) {
    return membershipType.slug === "nursind_employee" ? 1 : 0;
}

export function getCurrentTab(membershipType) {
    if (membershipType && membershipType.slug === "nursind_employee") {
        return "employee";
    } else if (membershipType) {
        return membershipType.slug;
    } else {
        return "member";
    }
}

export function getRegistrationType(membershipType) {
    if (membershipType) {
        return membershipType.slug;
    } else {
        return "member";
    }
}

export function getJurisdictionType(jurisdictionType) {
    if (jurisdictionType !== "member" && jurisdictionType !== "employee") {
        return jurisdictionType;
    } else {
        return "";
    }
}

export function isSuperAdmin(roles) {
    if (Array.isArray(roles)) {
        return roles.find((role) => role.name === "superAdmin") ? 1 : 0;
    } else {
        return roles;
    }
}

export function isMembershipLenghtCustom(membershipLenght) {
    if (
        membershipLenght.length === 0 ||
        membershipLenght === "1" ||
        membershipLenght === "6" ||
        membershipLenght === "12"
    ) {
        return 0;
    } else {
        return 1;
    }
}

export function openContractLength(contractTypeName) {
    if (contractTypeName.toLowerCase() === "determinato") {
        return 1;
    } else {
        0;
    }
}
