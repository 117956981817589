export function initJurisdictionsFields() {
    const unionDutiesSelect = $("#union_duty_id");

    unionDutiesSelect.on("select2:select", async () => {
        const selected = unionDutiesSelect.find(":selected");

        const url = window.utils.createApiUrl(
            "/api/union-duties",
            0,
            ["id", "need_jurisdiction"],
            [],
            [{ by: "id", value: [selected.val()], countFiltered: false }]
        );

        const response = await window.axios.get(url);
        const duty = response.data?.data?.[0];

        const radioNodes = document.querySelectorAll("input[jurisdictionType]");

        let oldJurisdictionTypeFound = false;
        for (let node of radioNodes) {
            if (duty.need_jurisdiction) {
                // Enable radio group
                if (node.hasAttribute("disabled")) {
                    node.removeAttribute("disabled");

                    const select = document.getElementById(
                        node.value == "regional"
                            ? "regional_jurisdiction"
                            : "provincial_jurisdiction"
                    );
                    if (select.hasAttribute("disabled")) {
                        select.removeAttribute("disabled");
                    }

                    if (!oldJurisdictionTypeFound) {
                        oldJurisdictionTypeFound = node.checked;
                    }
                }
            } else {
                if (!node.hasAttribute("disabled")) {
                    node.setAttribute("disabled", "disabled");
                    const select = document.getElementById(
                        node.value == "regional"
                            ? "regional_jurisdiction"
                            : "provincial_jurisdiction"
                    );
                    if (!select.hasAttribute("disabled")) {
                        select.setAttribute("disabled", "disabled");
                    }
                }
            }

            if (!oldJurisdictionTypeFound) {
                oldJurisdictionTypeFound = node.checked;
            }
        }

        if (!duty.need_jurisdiction) {
            const event = new CustomEvent("update-jurisdiction-type", {
                detail: {
                    jurisidictionType: "admin",
                },
            });

            window.dispatchEvent(event);
        } else {
            if (!oldJurisdictionTypeFound) {
                const event = new CustomEvent("update-jurisdiction-type", {
                    detail: {
                        jurisidictionType: "",
                    },
                });

                window.dispatchEvent(event);
            }
        }
    });

    if (unionDutiesSelect.find(":selected").length > 0) {
        unionDutiesSelect.trigger("select2:select");
    }
}

export function initCompanyFields(
    officeSelectId = "office_id",
    companySelectId = "company_id"
) {
    window.Alpine.store("is_office_selected", false);

    const officeSelect = $(`#${officeSelectId}`);
    officeSelect.on("select2:select", async () => {
        const option = officeSelect.find(":selected");

        // On select get all company related to the office
        const getJursidictionsUrl = window.utils.createApiUrl(
            "/api/offices",
            0,
            ["id", "provinceJurisdiction"],
            [],
            [{ by: "id", value: [option.val()], countFiltered: true }]
        );

        let provinces = [];
        try {
            const response = await window.axios.get(getJursidictionsUrl);
            provinces = await response.data.data[0].provinceJurisdiction.map(
                (value) => value.id
            );
        } catch (error) {
            console.error(error);
        }

        if (provinces && provinces.length) {
            const companySelect = window.Alpine.store(companySelectId);
            companySelect.setFilters([
                {
                    by: "province_id",
                    value: provinces,
                    countFiltered: true,
                },
            ]);

            window.Alpine.store("is_office_selected", true);
        }
    });

    officeSelect.on("select2:clear", () => {
        const companySelect = window.Alpine.store(companySelectId);
        companySelect.resetFilters();
        window.Alpine.store("is_office_selected", false);
    });

    const getAllCompaniesBtn = document.getElementById("get_all_companies");
    if (getAllCompaniesBtn) {
        getAllCompaniesBtn.addEventListener("click", () => {
            const companySelect = window.Alpine.store(companySelectId);
            companySelect.resetFilters();
            companySelect.$select.select2("data", null);
        });
    } else {
        console.warn("Get all offices button not found");
    }
}

export async function onExportCardBtnClick(path) {
    try {
        const getUsers = await axios.get(path);

        const csvFile = new Blob([await getUsers.data], {
            type: "text/csv",
        });
        const csvFileUrl = URL.createObjectURL(csvFile);
        location.href = csvFileUrl;

    } catch (error) {
        toastr.error(error.response.data.message);
    }
}

export async function generateCardNumber(officeId, cgs) {
    const url = `/api/offices/${officeId}/card/generate?cgs=${+cgs}`;
    try {
        const response = await window.axios.get(url);
        return await response.data;
    } catch (error) {
        window.toastr.error(error);
        console.error(error);
    }
}

export function forceUppercase(e) {
    const element = e.target;
    const start = element.selectionStart;
    const end = element.selectionEnd;
    element.value = element.value.toUpperCase();
    element.setSelectionRange(start, end);
}
