import "./bootstrap";

import.meta.glob(["../images/**"]);
// SASS import
import "../sass/app.scss";

import {
    fillForm,
    resetForm,
    urlContainsParameter,
    createApiUrl,
    showToastAndReload,
} from "./utils";
window.utils = {
    fillForm: fillForm,
    resetForm: resetForm,
    urlContainsParameter: urlContainsParameter,
    createApiUrl: createApiUrl,
    showToastAndReload: showToastAndReload,
};

import {
    userOverview,
    insuranceOverview,
    addedThisWeek,
    transferRequestsCount,
    countByMembershipTypes,
    disabledLastThirtyDays,
    usersByCompaniesCount,
    usersByAge,
    registeredDisabledTrend,
    usersRatios,
    memberCgsRatio,
    fromPastUnionsRatio,
    usersByCompanyType,
} from "./statistics";

import {
    onFilterSelected,
    submitChartOptions,
    onStatsSelected,
} from "./Views/statisticsIndex";

window.statistics = {
    userOverview: userOverview,
    insuranceOverview: insuranceOverview,
    addedThisWeek: addedThisWeek,
    transferRequestsCount: transferRequestsCount,
    countByMembershipTypes: countByMembershipTypes,
    disabledLastThirtyDays: disabledLastThirtyDays,
    usersByCompaniesCount: usersByCompaniesCount,
    usersByAge: usersByAge,
    registeredDisabledTrend: registeredDisabledTrend,
    usersRatios: usersRatios,
    memberCgsRatio: memberCgsRatio,
    fromPastUnionsRatio: fromPastUnionsRatio,
    usersByCompanyType: usersByCompanyType,
    index: {
        charts: {},
        onFilterSelected: onFilterSelected,
        submitChartOptions: submitChartOptions,
        onStatsSelected: onStatsSelected,
    },
};

import { Datatable } from "./Components/Datatable";
window.Datatable = Datatable;

import { Select2 } from "./Components/Select2";
window.Select2 = Select2;

import { submitData } from "./Views/userCheck";
window.submitData = submitData;

import { queryCreateEditDelete, destroy } from "./Views/queryCreateEditDelete";
window.query = {};
window.query.createEditDelete = queryCreateEditDelete;
window.query.destroy = destroy;

import {
    initJurisdictionsFields,
    initCompanyFields,
    onExportCardBtnClick,
    generateCardNumber,
    forceUppercase,
} from "./user";
import {
    userIndex,
    generatePreviewPanel,
    setUserStateValue,
    loadFilters,
    resetFilters,
} from "./Views/userIndex";
import {
    userEdit,
    isNursindEmployee,
    getCurrentTab,
    getRegistrationType,
    isSuperAdmin,
    getJurisdictionType,
    isMembershipLenghtCustom,
    openContractLength,
} from "./Views/userEdit";
import { userCreate } from "./Views/userCreate";
import {
    approveTransferRequest,
    rejectTransferRequest,
} from "./Views/userShow";
import { generateNewCardNumber } from "./Views/generateNewCardNumber";

window.user = {
    initJurisdictionsFields: initJurisdictionsFields,
    initCompanyFields: initCompanyFields,
    onExportCardBtnClick: onExportCardBtnClick,
    generateCardNumber: generateCardNumber,
    index: {
        init: userIndex,
        generatePreviewPanel: generatePreviewPanel,
        setUserStateValue: setUserStateValue,
        loadFilters: loadFilters,
        resetFilters: resetFilters,
    },
    show: {
        approveTransferRequest: approveTransferRequest,
        rejectTransferRequest: rejectTransferRequest,
    },
    edit: {
        init: userEdit,
        isNursindEmployee: isNursindEmployee,
        getCurrentTab: getCurrentTab,
        getRegistrationType: getRegistrationType,
        isSuperAdmin: isSuperAdmin,
        getJurisdictionType: getJurisdictionType,
        isMembershipLenghtCustom: isMembershipLenghtCustom,
        openContractLength: openContractLength,
    },
    create: { init: userCreate },
    generateNewCardNumber: { init: generateNewCardNumber },
    forceUppercase: forceUppercase,
};

import { beforeSend } from "./Views/newsletterShow";
window.newsletter = { show: { beforeSend: beforeSend } };

import { overview } from "./Views/overview";
window.overview = overview;

import { Map } from "./Components/Map";
window.GMap = Map; // Non posso chiamarlo Map perhè esiste già una classe con questo nome caricata da qualche dipendeza esterna e vite fa schifo a gestire ste cose a quanto pare.

import { officeIndex } from "./Views/officeIndex";
import { loadUsersChart, loadUsersByCompanies } from "./Views/officeShow";
import { onMarkerPlaced } from "./Views/officeCreate";
window.office = {
    onMarkerPlaced: onMarkerPlaced,
    index: { init: officeIndex },
    show: {
        loadUsersChart: loadUsersChart,
        loadUsersByCompanies: loadUsersByCompanies,
    },
};

import { transferRequestCreate } from "./Views/transferRequestCreate";
window.transferRequest = { create: { init: transferRequestCreate } };

import { destroy as deleteIteModal } from "./Components/DeleteItemModal";
window.deleteItemModal = { destroy: deleteIteModal };

import ConfirmNewslettersJobModal from "./Components/ConfirmNewslettersJobModal";
window.ConfirmNewslettersJobModal = ConfirmNewslettersJobModal;

// On dom ready callback functions queue
window.onDomReady = [];
window.addEventListener("DOMContentLoaded", () => {
    Alpine.start();

    for (let func of window.onDomReady) {
        func();
    }
});
