export const Query = {
    name: "",
    description: "",
    is_private: "",
    rule: "",
    filters: function (logics = []) {
        const filters = [];

        for (let field of Object.keys(this.rule)) {
            filters.push({
                by: field,
                value: [].concat(this.rule[field]),
                countFiltered: !logics.includes(field),
            });
        }

        return filters;
    },
};

export const getOne = async (id) => {
    const response = await axios.get(
        `/api/queries?draw_counter=0&columns[]=id&columns[]=name&columns[]=rule&columns[]=description&columns[]=is_private&filters[0][by]=id&filters[0][value][0]=${id}`
    );

    const query = Object.create(Query);
    query.name = response.data.data[0]?.name;
    query.description = response.data.data[0]?.description;
    query.is_private = response.data.data[0]?.is_private;
    query.rule = JSON.parse(response.data.data[0]?.rule);

    return query;
};
