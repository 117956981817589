import axios from "axios";

// Astrarre se vedo che devo riusarla
export async function submitData(data, element) {
    element.setAttribute("disabled", "disabled");
    const formData = new FormData();
    for (let field of Object.keys(data)) {
        if (Array.isArray(data[field])) {
            for (let value of data[field]) {
                formData.append(field + "[]", value);
            }
        } else {
            if (!data[field]) {
                formData.append(field, "");
            } else {
                formData.append(field, data[field]);
            }
        }
    }

    const response = await axios.post("/users", formData);

    const userId = await response.data;

    location.href = `/users/${userId}/upload?creating=true`;
}
