import axios from "axios";
import { getOne } from "../query";

export async function queryCreateEditDelete() {
    // On query_id selected autofill fields
    const $querySelect = $("#query_id");

    $querySelect.on("select2:select", async (e) => {
        const data = e.params.data;
        const query = await getOne(data.id);
        const response = await axios.post("/api/queries/unfold", query.rule);
        const formData = response.data;

        window.utils.resetForm(["query_id"]);

        // Fill form
        window.utils.fillForm({
            query_name: query.name,
            description: query.description,
            is_private: query.is_private,
            ...formData,
        });

        for (let name in formData) {
            const fields = document.querySelectorAll(`*[name^='${name}']`);

            let valueFound = false;
            let inputContainer;
            for (let field of fields) {
                if (field.tagName == "INPUT") {
                    // Skip checkboxes
                    if (field.type == "checkbox") {
                        continue;
                    }

                    // Check if input is filled or checked (radio)
                    if (field.type == "radio" && field.checked) {
                        valueFound = true;
                    } else {
                        valueFound = field.value ? true : false;
                    }

                    inputContainer = field.closest(".w-50");
                } else if (field.tagName == "SELECT") {
                    const $select = $(field);
                    valueFound = $select.select2("data")[0] ? true : false;
                    inputContainer = field.parentElement;
                }

                // Enable fields
                if (valueFound && inputContainer) {
                    const checkbox = inputContainer.querySelector(
                        "input:not([disabled])[type='checkbox']"
                    );

                    if (checkbox && !checkbox.checked) {
                        checkbox.click();
                    }

                    // Handle date input type special case
                    if (field.tagName == "INPUT" && field.type == "date") {
                        const rangeCheckbox =
                            inputContainer.querySelector("input[range]");

                        if (rangeCheckbox.hasAttribute("disabled")) {
                            rangeCheckbox.removeAttribute("disabled");
                        }

                        const endRangeDate =
                            inputContainer.querySelectorAll(
                                "input[type='date']"
                            )?.[1];

                        if (
                            endRangeDate &&
                            endRangeDate.value &&
                            !rangeCheckbox.checked
                        ) {
                            rangeCheckbox.click();
                        }
                    }
                    break;
                }
            }
        }

        // Enable update and delete buttons
        document
            .querySelectorAll("button[action='update']")
            ?.forEach((button) => {
                button.removeAttribute("disabled");
            });

        document
            .querySelectorAll("button[action='delete']")
            ?.forEach((button) => {
                button.removeAttribute("disabled");
            });

        // Disable create button
        document
            .querySelectorAll("button[action='create']")
            ?.forEach((button) => {
                button.setAttribute("disabled", "disabled");
            });
    });

    $querySelect.on("select2:clear", () => {
        window.utils.resetForm(["query_id"]);

        // Brutto ma non ho tempo, workaround per far funzionare il reset senza prendersi i parametri settati su alpine.
        // Non ho neanche modo di definire lo scope e i parametri hanno lo stesso nome.
        const elements = document.querySelectorAll("[name]");
        let inputContainer;
        for (let element of elements) {
            if (element.tagName == "INPUT") {
                inputContainer = element.closest(".w-50");
            } else if (element.tagName == "SELECT") {
                inputContainer = element.parentElement;
            }

            if (inputContainer && !element.hasAttribute("disabled")) {
                const checkbox = inputContainer.querySelector(
                    "input:not([disabled])[type='checkbox']"
                );

                if (checkbox && !checkbox.checked) {
                    checkbox.click();
                    checkbox.checked = false;
                }
            }
        }

        // Disable update and delete buttons
        document
            .querySelectorAll("button[action='update']")
            ?.forEach((button) => {
                button.setAttribute("disabled", "disabled");
            });

        document
            .querySelectorAll("button[action='delete']")
            ?.forEach((button) => {
                button.setAttribute("disabled", "disabled");
            });

        // Enable create button
        document
            .querySelectorAll("button[action='create']")
            ?.forEach((button) => {
                button.removeAttribute("disabled");
            });
    });
}

export async function destroy() {
    const queryId = $("#query_id").find(":selected")?.val();

    if (queryId) {
        try {
            await axios.delete(`/queries/${queryId}`);
            location.href = "/users";
        } catch (error) {
            if (error.response.status == 403) {
                window.toastr.error(
                    "Non sei autorizzato a cancellare questa query"
                );
            }
        }
    } else {
        console.warn("No selected option found.");
        window.toastr.error("Selezionare una query da eliminare.");
    }
}
