// CHARTS
const sizes = () => {
    if (window.innerWidth < 1500) {
        return {
            title: 18,
            subtitle: 10,
            legend: 10,
            radius: ["20%", "35%"],
        };
    } else if (window.innerWidth < 1700) {
        return {
            title: 21,
            subtitle: 12,
            legend: 10,
            radius: ["20%", "35%"],
        };
    }

    return {
        title: 28,
        subtitle: 14,
        legend: 12,
        radius: ["40%", "55%"],
    };
};

const charts = {
    pie: {
        title: {
            textStyle: {
                fontSize: sizes().title,
                fontFamily: "Poppins",
            },
            subtextStyle: {
                fontSize: sizes().subtitle,
                fontFamily: "Poppins",
            },
        },
        tooltip: {
            trigger: "item",
        },
        legend: {
            top: "center",
            left: "right",
            orient: "vertical",
            textStyle: {
                fontSize: sizes().legend,
                fontFamily: "Poppins",
            },
        },
        series: [
            {
                /*  name: "Stato assicurazione", */
                type: "pie",
                top: "-30%",
                left: "-40%",
                right: "-30%",
                bottom: "-30%",
                radius: sizes().radius,
                /* color: ["#F1416C", "#50CD89", "#41a6f1", "#f1df41"], */
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 10,
                    borderColor: "#fff",
                    borderWidth: 2,
                },
                label: {
                    show: false,
                    position: "center",
                },
                labelLine: {
                    show: false,
                },
            },
        ],
    },
    bar: {
        xAxis: {
            type: "category",
        },
        yAxis: {
            type: "value",
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "shadow",
            },
        },
        toolbox: {
            feature: {
                saveAsImage: {},
                dataZoom: {
                    yAxisIndex: false,
                },
            },
        },
        dataZoom: [
            {
                type: "inside",
            },
            {
                type: "slider",
            },
        ],
        series: [
            {
                type: "bar",
            },
        ],
    },
};

export async function userOverview(
    chartContainerId,
    filterCgs,
    filter,
    value,
    end,
    canDownload = false
) {
    const data = await getStatsData("/api/statistics/users/overview", {
        filter_cgs: +filterCgs,
        cgs: window.utils.urlContainsParameter("cgs") ? 1 : 0,
        filter: filter,
        value: value,
        end: end,
    });

    const chartNode = document.getElementById(chartContainerId);

    const chartOptions = structuredClone(charts.pie);
    chartOptions.title.text = data.enabled + data.disabled;
    chartOptions.title.subtext = "Iscritti totali";
    chartOptions.series[0].data = [
        { value: data.enabled, name: "Attivi" },
        { value: data.disabled, name: "Disattivi" },
    ];
    chartOptions.series[0].name = "Iscritti totali";
    chartOptions.series[0].color = ["#50CD89", "#F1416C"];
    if (canDownload) {
        chartOptions.toolbox = {
            feature: {
                saveAsImage: {},
            },
        };
    }

    const chart = window.echarts.init(chartNode);
    chart.setOption(chartOptions);

    window.addEventListener("resize", () => {
        chart.resize();
    });

    return chart;
}

export async function insuranceOverview(
    chartContainerId,
    filterCgs,
    filter,
    value,
    end,
    canDownload = false
) {
    const data = await getStatsData(
        "/api/statistics/users/insurance-overview",
        {
            filter_cgs: +filterCgs,
            filter: filter,
            value: value,
            end: end,
        }
    );

    const chartNode = document.getElementById(chartContainerId);

    const chartOptions = structuredClone(charts.pie);
    chartOptions.title.text =
        data.to_insure + data.insured + data.to_not_insure + data.was_insured;
    chartOptions.title.subtext = "Assicurazione";
    chartOptions.series[0].name = "Stato assicurazione";
    chartOptions.series[0].color = ["#F1416C", "#50CD89", "#41a6f1", "#f1df41"];
    chartOptions.series[0].data = [
        { value: data.to_insure, name: "In corso" },
        { value: data.insured, name: "Assicurato" },
        { value: data.to_not_insure, name: "Da non \n assicurare" },
        { value: data.was_insured, name: "Postumo" },
    ];
    if (canDownload) {
        chartOptions.toolbox = {
            feature: {
                saveAsImage: {},
            },
        };
    }

    const chart = window.echarts.init(chartNode);
    chart.setOption(chartOptions);

    window.addEventListener("resize", () => {
        chart.resize();
    });

    return chart;
}

export async function usersByAge(chartContainerId, filterCgs, filter, value) {
    const usersByAge = await getStatsData("/api/statistics/users/by-age", {
        filter_cgs: +filterCgs,
        filter: filter,
        value: value,
    });

    const chartNode = document.getElementById(chartContainerId);
    const chartOptions = {
        xAxis: {
            type: "category",
            data: usersByAge.map((item) => item.age),
        },
        yAxis: {
            type: "value",
        },
        series: [
            {
                data: usersByAge.map((item) => item.count),
                type: "bar",
            },
        ],
        dataZoom: [
            {
                type: "inside",
            },
            {
                type: "slider",
            },
        ],
    };

    const chart = window.echarts.init(chartNode).setOption(chartOptions);

    window.addEventListener("resize", () => {
        chart.resize();
    });

    return chart;
}

export async function countByMembershipTypes(
    chartContainerId,
    filterCgs,
    canDownload = false
) {
    const data = await getStatsData("/api/statistics/users/membership-types", {
        filter_cgs: +filterCgs,
    });

    const chartNode = document.getElementById(chartContainerId);

    const chartOptions = structuredClone(charts.pie);
    chartOptions.title.text = data.member + data.employee + data.retired;
    chartOptions.title.subtext = "Tipo iscrizione";
    chartOptions.series[0].name = "Tipo iscrizione";
    chartOptions.series[0].color = ["#41a6f1", "#50CD89", "#f1df41"];
    chartOptions.series[0].data = [
        { value: data.member, name: "Iscritto" },
        { value: data.employee, name: "Dirigente \n sindacale" },
        { value: data.retired, name: "Pensionato" },
    ];

    if (canDownload) {
        chartOptions.toolbox = {
            feature: {
                saveAsImage: {},
            },
        };
    }

    const chart = window.echarts.init(chartNode).setOption(chartOptions);

    window.addEventListener("resize", () => {
        chart.resize();
    });

    return chart;
}

export async function registeredDisabledTrend(
    chartContainerId,
    baseUrl,
    queryParams
) {
    const data = await getStatsData(baseUrl, queryParams);

    const chartNode = document.getElementById(chartContainerId);
    const chartOptions = {
        xAxis: {
            type: "category",
            data: data.registered.map((item) => item.x),
        },
        yAxis: {
            type: "value",
        },
        toolbox: {
            feature: {
                saveAsImage: {},
                dataZoom: {
                    yAxisIndex: false,
                },
            },
        },
        dataZoom: [
            {
                type: "inside",
            },
            {
                type: "slider",
            },
        ],
        tooltip: {
            trigger: "axis",
        },
        series: [
            {
                name: "Registrati",
                data: data.registered.map((item) => item.y),
                type: "line",
            },
            {
                name: "Disattivati",
                data: data.disabled.map((item) => item.y),
                type: "line",
            },
        ],
    };

    const chart = window.echarts.init(chartNode);
    chart.setOption(chartOptions);

    window.addEventListener("resize", () => {
        chart.resize();
    });

    return chart;
}

export async function usersRatios(chartContainerId, url, end) {
    const data = await getStatsData(url, { end: end }, true);

    const chartNode = document.getElementById(chartContainerId);

    const chartOptions = structuredClone(charts.bar);
    chartOptions.xAxis.data = data.map((item) => item.label);
    chartOptions.series[0].data = data.map((item) => item.value);
    chartOptions.toolbox = {
        feature: {
            saveAsImage: {},
        },
    };

    const chart = window.echarts.init(chartNode);
    chart.setOption(chartOptions);

    window.addEventListener("resize", () => {
        chart.resize();
    });

    return chart;
}

export async function memberCgsRatio(
    chartContainerId,
    filterCgs,
    filter,
    value,
    end,
    canDownload = true
) {
    const data = await getStatsData("/api/statistics/users/member-cgs-ratio", {
        filter_cgs: +filterCgs,
        filter: filter,
        value: value,
        end: end,
    });

    const chartNode = document.getElementById(chartContainerId);

    const chartOptions = structuredClone(charts.pie);
    chartOptions.title.text = data.members + data.cgs;
    chartOptions.title.subtext = "Iscritti/CGS";
    chartOptions.series[0].name = "Iscritti totali";
    chartOptions.series[0].color = ["#41a6f1", "#50CD89"];
    chartOptions.series[0].data = [
        { value: data.members, name: "Iscritti" },
        { value: data.cgs, name: "CGS" },
    ];

    if (canDownload) {
        chartOptions.toolbox = {
            feature: {
                saveAsImage: {},
            },
        };
    }

    const chart = window.echarts.init(chartNode);
    chart.setOption(chartOptions);

    window.addEventListener("resize", () => {
        chart.resize();
    });

    return chart;
}

export async function fromPastUnionsRatio(
    chartContainerId,
    filter,
    value,
    end
) {
    const data = await getStatsData(
        "/api/statistics/users/past-unions-ratios",
        {
            filter: filter,
            value: value,
            end: end,
            filter_cgs: 0,
            cgs: 0,
        }
    );

    const chartNode = document.getElementById(chartContainerId);

    const chartOptions = structuredClone(charts.bar);
    chartOptions.xAxis.data = data.map((item) => item.name);
    chartOptions.series[0].data = data.map((item) => item.count);

    const chart = window.echarts.init(chartNode).setOption(chartOptions);

    window.addEventListener("resize", () => {
        chart.resize();
    });

    return chart;
}

//SUMS
export async function addedThisWeek(filterCgs) {
    return await getStatsData("/api/statistics/users/this-week", {
        filter_cgs: +filterCgs,
        cgs: window.utils.urlContainsParameter("cgs") ? 1 : 0,
    });
}

export async function disabledLastThirtyDays(filterCgs) {
    return await getStatsData("/api/statistics/users/disabled-last-days", {
        filter_cgs: +filterCgs,
        cgs: window.utils.urlContainsParameter("cgs") ? 1 : 0,
    });
}

export async function transferRequestsCount() {
    return await getStatsData("/api/statistics/transfer-requests");
}

export async function usersByCompaniesCount(filterCgs, filter, value) {
    return await getStatsData("/api/statistics/users/by-companies", {
        filter_cgs: +filterCgs,
        filter: filter,
        value: value,
    });
}

export async function usersByCompanyType(
    chartContainerId,
    filter,
    value,
    end,
    cgs
) {
    console.log(value);
    const data = await getStatsData("/api/statistics/users/by-company-types", {
        filter: filter,
        value: value,
        end: end,
        filter_cgs: 1,
        cgs: +cgs,
    });

    const chartNode = document.getElementById(chartContainerId);
    const chartOptions = structuredClone(charts.pie);
    chartOptions.title.text = data.reduce((acc, item) => acc + item.value, 0);
    chartOptions.title.subtext = "Iscritti per tipo di azienda";
    chartOptions.series[0].name = "Iscritti per tipo di azienda";
    chartOptions.series[0].data = data.map((item) => {
        return { value: item.value, name: item.label };
    });
    chartOptions.toolbox = {
        feature: {
            saveAsImage: {},
        },
    };
    const chart = window.echarts.init(chartNode);
    chart.setOption(chartOptions);

    window.addEventListener("resize", () => {
        chart.resize();
    });

    return chart;
}

// UTILS
async function getStatsData(
    baseUrl,
    queryParams = {},
    firstParamAdded = false
) {
    for (let key of Object.keys(queryParams)) {
        const value = queryParams[key];
        if (value !== null && value !== undefined) {
            if (firstParamAdded) {
                baseUrl += "&";
            } else {
                baseUrl += "?";
                firstParamAdded = true;
            }
            baseUrl += key + "=" + value;
        }
    }

    try {
        const response = await window.axios.get(baseUrl);
        return response.data.data;
    } catch (e) {
        window.toastr.error("Errore nel caricamento dei dati");
        setTimeout(window.toastr.clear, 2000);
    }
}
