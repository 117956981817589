// Select2
import select2 from "select2";
select2();

export class Select2 {
    constructor(elementId, options, lock = true) {
        this.options = options;
        this.select = document.getElementById(elementId);

        const selectOptions = {
            selectionCssClass: ":all:",
            theme: "bootstrap5",
            placeholder: options.placeholder,
            allowClear: true,
            width: "100%",
        };

        if (options.data && options.data.length > 0) {
            selectOptions.data = options.data;
        } else {
            selectOptions.ajax = {
                url: options.dataEndpoint,
                dataType: "json",

                data: (params) => this._getCustomHttpParameters(params),

                processResults: (response) =>
                    this._handleCustomHttpResponse(response),
            };
        }

        this.$select = $(this.select).select2(selectOptions);

        if (options.relatedSelect) {
            this.relatedSelect = document.getElementById(options.relatedSelect);
            this.$relatedSelect = this.relatedSelect
                ? $(this.relatedSelect)
                : undefined;

            if (lock) {
                const selected = this.$relatedSelect.find(":selected");
                if (!selected.length) {
                    $(`#${elementId}`).prop("disabled", true);
                }

                this.$relatedSelect.on("select2:select", () => {
                    if (this.$select.prop("disabled")) {
                        this.$select.prop("disabled", false);
                    }

                    this.$select.val(null).trigger("change");
                    this.$select.trigger({ type: "select2:clear" });
                });

                this.$relatedSelect.on("select2:clear", () => {
                    if (!this.$select.prop("disabled")) {
                        this.$select.prop("disabled", true);
                    }

                    this.$select.val(null).trigger("change");

                    this.$select.trigger({ type: "select2:clear" });
                });
            }
        }

        if (options.filters) {
            this.setFilters(options.filters);
        }

        // Store instace inside Alpine store so it can be retrieved later
        window.Alpine.store(elementId, this);

        // Trigger select2:select event if select already has a value
        if (this.$select.select2("data")[0]) {
            this.$select.trigger({
                type: "select2:select",
                params: { data: this.$select.select2("data")[0] },
            });
        }
    }

    setFilters(filters) {
        this.customFilters = filters;
    }

    resetFilters() {
        this.customFilters = [];
    }

    enabled(isEnabled) {
        this.$select.prop("disabled", !isEnabled);
    }

    _getCustomHttpParameters(legacyParameters) {
        const relatedId =
            this.$relatedSelect && this.$relatedSelect.select2("data")[0]
                ? this.$relatedSelect.select2("data")[0].id
                : "";

        const params = {
            draw_counter: 0,
            term: legacyParameters.term,
            columns: ["id", this.options.column],
        };

        params.filters = relatedId
            ? [
                  {
                      by: this.options.relationshipColumn,
                      value: [relatedId],
                      countFiltered: true,
                  },
              ]
            : this.customFilters;

        return params;
    }

    _handleCustomHttpResponse(response) {
        const formattedResponse = { results: [] };
        for (let entry of response.data) {
            formattedResponse.results.push({
                id: entry.id,
                text: entry[this.options.column],
            });
        }

        return formattedResponse;
    }
}
